import {set} from "@vueuse/core";

export const useProgressIndicatorStore = defineStore('progressIndicatorStore', () => {
	const status = ref(false);

	function updateStatus(value: boolean) {
		set(status, value);
	}

	return {
		status,
		updateStatus
	}
})
